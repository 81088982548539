.settings-sections {
	margin: var(--spacing-3) 0 0;
	display: grid;
	grid-gap: var(--spacing-4);

	&__item {
		&-title {
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 20, 22);
			font-weight: var(--font-weight-semibold);
			color: var(--color-heading);
			margin: 0 0 var(--spacing-1);
		}

		&-value {
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 18, 20);
			font-weight: var(--font-weight-light);
			color: var(--color-heading);
			margin: 0 0 var(--spacing-2);
			background-color: #f6f9fa;
			padding: var(--spacing-2);
			border-radius: 10px;
		}
	}

	.settings-form {
		max-width: 800px;
	}

	.settings-form .form__fields {
		grid-template-columns: 1fr 1fr;

		.secondary-btn {
			grid-column: 1 / 3;
			max-width: fit-content;
		}
	}
}