.MuiToggleButton-root {
	border-radius: 50%;
	color: var(--base-link-color);
	background-color: #e9f0ff;
	border: none;
	width: 36px;
	height: 36px;
}

.MuiToggleButton-root:hover {
	color: var(--base-link-color);
	background-color: #e9f0ff;
}

.MuiToggleButton-root svg {
	width: 20px;
	height: 20px;
}

.MuiToggleButton-root.Mui-selected,
.MuiToggleButton-root.Mui-selected:hover {
	background-color: var(--color-1);
	color: #fff;
}

.MuiStepLabel-labelContainer span {
	color: #8897a1;
	font-weight: var(--font-weight-regular);
	z-index: 1;
	font-family: var(--base-font-family);
	display: block;
	text-align: center;
	font-size: 1rem;
}

.MuiStepIcon-root {
	color: #f7f4e2;
}

.MuiStepIcon-text {
	fill: #8897a1;
}

.MuiStepIcon-root.Mui-active {
	color: var(--color-1);
}

.MuiStepIcon-root.Mui-active .MuiStepIcon-text {
	fill: #fff;
}

.MuiStepConnector-root .MuiStepConnector-line {
	border-color: #f2f3f4;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
	background-color: var(--color-heading);
	color: #fff;
	font-size: 0.75rem;
	font-family: var(--base-font-family);
}

.MuiListSubheader-root {
	font-family: var(--font-family-heading);
	color: var(--color-heading);
	font-weight: var(--font-weight-bold);
	font-size: 1rem;
}

.MuiListItemButton-root {
	border-bottom: 1px solid #eee;
	gap: var(--spacing-1);
}

.MuiListItemButton-root:last-of-type {
	border-bottom: 0;
}

.MuiListItemButton-root:hover {
	background-color: var(--color-text-light);
}

.MuiListItemButton-root .MuiListItemText-primary {
	color: var(--color-heading);
	font-weight: var(--font-weight-medium);
	font-family: var(--base-font-family);
	font-size: var(--base-font-size);
}

.MuiListItemButton-root .MuiListItemText-secondary {
	color: #8897a1;
	font-weight: var(--font-weight-medium);
	font-family: var(--base-font-family);
	font-size: 0.875rem;
}

.MuiListItemButton-root .MuiListItemIcon-root {
	min-width: 36px;
}
