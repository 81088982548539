.invoice-list {
	&__sorting {
		grid-template-columns: 60px 1fr 1fr 1fr 1fr 120px;
	}

	&__item {
		&-link {
			grid-template-columns: 60px 1fr 1fr 1fr 1fr 60px;
		}

		&:hover {
			.invoice-list__item-number {
				color: var(--color-heading);
			}
		}

		&.type--open {
			.invoice-list__item-icon svg {
				color: #80a7ff;
				background-color: #e9f0ff;
			}
		}

		&.type--paid {
			.invoice-list__item-icon svg {
				color: #9cecb2;
				background-color: #e3ffea;
			}
		}

		&.type--uncollectible,
		&.type--void {
			.invoice-list__item-icon svg {
				color: #ed93ef;
				background-color: #ffe7fd;
			}
		}

		&.type--draft {
			.invoice-list__item-icon svg {
				color: #e0e051;
				background-color: #ffffc9;
			}
		}
	}
}
