.document-list,
.invoice-list,
.member-list {
	width: 100%;
	margin: var(--spacing-3) 0;

	&__title {
		font-family: var(--font-family-heading);
		font-size: autosize(1041, 1920, 20, 22);
		font-weight: var(--font-weight-semibold);
		color: var(--color-heading);
		margin: 0 0 var(--spacing-2);
	}

	&__sorting {
		display: grid;
		grid-template-columns: 60px 1fr 1fr 120px;

		&-name,
		&-edited,
		&-email,
		&-added,
		&-number,
		&-status,
		&-amount,
		&-date,
		&-usage {
			color: #8897a1;
			font-weight: var(--font-weight-medium);
			font-size: 14px;

			&:first-of-type {
				grid-column-start: 2;
			}
		}
	}

	&__none-found {
		max-width: 800px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;

		&-illustration {
			max-width: 30%;
		}

		&-text {
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 20, 22);
			font-weight: var(--font-weight-semibold);
			color: var(--color-heading);

			span {
				display: block;
				font-size: 1rem;
				color: #8897a1;
				font-weight: var(--font-weight-medium);
				margin: 5px 0 0;
			}

			a {
				text-decoration: none;
				color: var(--color-1);
			}
		}
	}

	&__item {
		display: grid;
		grid-template-columns: 1fr 60px;
		align-items: center;
		cursor: pointer;
		border-bottom: 2px solid #f6f6f7;

		&-link {
			display: grid;
			grid-template-columns: 60px 1fr 1fr 60px;
			align-items: center;
			text-decoration: none;
			padding: 15px 0;
		}

		&:hover {
			.document-list__item-name {
				color: var(--color-heading);
			}
		}

		&-name,
		&-edited,
		&-number,
		&-status,
		&-amount,
		&-date,
		&-added,
		&-email,
		&-usage {
			font-weight: var(--font-weight-medium);
			color: #8897a1;
		}

		&-icon {
			margin: 2px 0 0;

			svg {
				border-radius: 50%;
				width: 40px;
				height: 40px;
				padding: 9px;
			}
		}

		&-settings {
			color: #8897a1;
		}

		&-settings-menu ul {
			padding: 0;

			li {
				color: #778684;
				font-family: var(--base-font-family);
				padding: 12px 15px;
				font-size: 0.875rem;
				font-weight: var(--font-weight-medium);

				&:hover,
				&:active,
				&:focus {
					color: var(--color-1);
					background-color: #f8f9fc;
				}
			}
		}

		&:last-of-type {
			border-bottom: 0;
		}
	}

	&.is-loading {
		margin-top: 50px;
	}
}
