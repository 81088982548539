.password-strength-indicator {
	width: 100%;
	display: flex;
    flex-direction: column;
	margin: -10px 0 0;
	transition: 500ms linear;
    font-family: var(--base-font-family);
    font-size: 0.875rem;
    color: #8897a1;

	.indi {
		width: 100%;

        b {
            font-weight: var(--font-weight-medium);
            margin-right: 5px;
        }
	}

	.bar {
        margin: 5px 0 0;
		width: 0%;
		height: 6px;
		border-radius: 3px;
		position: relative;
        background-color: var(--color-error);
        transition: 300ms linear width;
	}
}

.password-strength-indicator.very-weak {
    .strength-type {
        color: var(--color-error);
    }

    .bar {
        width: 25%;
    }
}

.password-strength-indicator.weak {
    .strength-type {
        color: #f36739;
    }

	.bar {
		width: 50%;
		background-color: #f36739;
	}
}

.password-strength-indicator.medium {
    .strength-type {
        color: #ffe034;
    }

	.bar {
		width: 75%;
		background-color: #ffe034;
	}
}

.password-strength-indicator.strong {
    .strength-type {
        color: #56d771;
    }

	.bar {
		width: 100%;
		background-color: #56d771;
	}
}