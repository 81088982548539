body .basic__modal {
	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.2);
	}

	&-box {
		border: none;
		border-radius: 10px;
		box-shadow: var(--card-shadow);
		padding: var(--spacing-6) var(--spacing-4);
		position: relative;

		&[disabled]:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.5);
			z-index: 1;
		}
	}

	&-title {
		font-family: var(--font-family-heading);
		font-size: autosize(1041, 1920, 30, 36);
		font-weight: var(--font-weight-extrabold);
		color: var(--color-heading);
		margin: 0;
		text-align: center;

		span {
			position: relative;

			.basic__modal-title-brush {
				display: inline-block;
				position: absolute;
				width: calc(100% + 30px);
				margin: 0 auto;
				left: -15px;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: -1;
				height: 50px;
			}
		}
	}

	&-description {
		font-family: var(--font-family-heading);
		font-weight: var(--font-weight-regular);
		color: #8897a1;
		margin: var(--spacing-1) 0 0;
		text-align: center;

		span {
			font-weight: var(--font-weight-medium);
			color: var(--base-button-color);
		}
	}
}