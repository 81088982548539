.member-list {
	&__sorting {
		grid-template-columns: 60px 1fr 1fr 1fr 60px;
	}

	&__item {
		&-link {
			grid-template-columns: 60px 1fr 1fr 1fr;
		}

		&-icon svg {
			color: #80a7ff;
			background-color: #e9f0ff;
		}
	}
}