body .create-content__modal {
	.form__fields {
		&-types {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 10px;
			margin: var(--spacing-2) 0 var(--spacing-4);
		}

		&-types-field {
			width: 100%;
			cursor: pointer;
			user-select: none;

			svg {
				border-radius: 50%;
				width: 40px;
				height: 40px;
				padding: 9px;
				margin-right: 12px;
			}

			&.type--1 {
				svg {
					color: #9cecb2;
					background-color: #e3ffea;
				}
			}

			&.type--2 {
				svg {
					color: #e0e051;
					background-color: #ffffc9;
				}
			}

			&.type--3 {
				svg {
					color: #ed93ef;
					background-color: #ffe7fd;
				}
			}

			&-first {
				display: flex;
				align-items: center;
			}

			&-name {
				font-weight: var(--font-weight-semibold);
				color: var(--color-heading);
			}

			&-description {
				color: #8897a1;
				font-weight: var(--font-weight-regular);
				flex-basis: 100%;
				margin: 5px 0 0;
			}

			&-brush {
				display: none;
			}
		}

		&-types-field.active {
			.form__fields-types-field-name {
				position: relative;
				color: var(--color-1);
			}

			.form__fields-types-field-brush {
				display: inline-block;
				position: absolute;
				width: calc(100% + 26px);
				left: -13px;
				top: -5px;
				right: 0;
				bottom: 0;
				z-index: -1;
				height: 30px;
			}
		}

		&-types-field:not(.active) {
			&:hover {
				.form__fields-types-field-name {
					color: var(--color-1);
				}
			}
		}
	}
}