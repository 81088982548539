/* stylelint-disable declaration-colon-space-after */
@import 'postcss-variables.css';

/* Custom properties */
:root {
	/* Grid */
	--max-width: $(max-width)px;
	--max-width-header: $(max-width-header)px;
	--max-width-list: $(max-width-list)px;
	--max-width-pagination: $(max-width-pagination)px;
	--max-width-content-wide: $(max-width-content-wide)px;
	--max-width-content: $(max-width-content)px;
	--grid-gutter-width: $(grid-gutter-width)px;
	--grid-col-width: $(grid-col-width)px;

	/* Typography */
	--base-font-family: 'Nunito', sans-serif;
	--secondary-font-family: 'Lexend', sans-serif;
	--font-family-heading: var(--base-font-family);

	/* Type: Sizes */
	--base-font-size:              autosize(400, 1920, 16, 16);
	--font-size-caption:           autosize(400, 1920, 24, 32);
	--font-size-excerpt:           autosize(400, 1920, 18, 20);
	--font-size-small:             autosize(400, 1920, 14, 14);
	--font-size-large:             autosize(400, 1920, 24, 24);
	--font-size-h1:                autosize(400, 1920, 30, 80);
	--font-size-h2:                autosize(400, 1920, 36, 54);
	--font-size-h3:                autosize(400, 1920, 32, 42);
	--font-size-h4:                autosize(400, 1920, 30, 36);
	--font-size-h5:                autosize(400, 1920, 28, 32);
	--font-size-h6:                autosize(400, 1920, 24, 28);
	--font-size-widget:            autosize(400, 1920, 20, 20);
	--font-size-widget-title:      autosize(400, 1920, 24, 24);

	/* Type: Line height */
	--base-line-height:       calc(44 / 32);
	--line-height-heading:    calc(44 / 32);

	/* Type: Font weight */
	--font-weight-light:     300;
	--font-weight-regular:   400;
	--font-weight-medium:    500;
	--font-weight-semibold:  600;
	--font-weight-bold:      700;
	--font-weight-extrabold: 800;
	--font-weight-black: 900;

	/* Colors */
	--color-1: #3d6edd;
	--color-2: #4d7eec;
	--color-text: #666f78;
	--color-text-light: #fcfcfc;
	--color-heading: #3a4652;
	--color-separator: #d7d7d7;
	--color-gray-1: #ccc;
	--color-error: #ed3a3a;

	/* Link colors */
	--base-link-color: var(--color-1);
	--base-link-color-2: #6f91dd;
	--hover-link-color: var(--color-2);
	--base-button-color: var(--color-1);
	--hover-button-color: var(--color-2);
	--disabled-button-color: #3d6edd95;

	/* Border color */
	--base-border-color: #eee;
	--dark-border-color: #ddd;
	--base-border: 1px solid var(--base-border-color);
	--dark-border: 1px solid var(--dark-border-color);
	--light-border-color: #f0f0f0;
	--light-border: 1px solid var(--light-border-color);
	--border-radius: 3px;

	/* Shadow color */
	--base-shadow-color: #fcfcfc;

	/* Box shadow */
	--card-shadow: 0 2px 4px rgb(0 0 0 / 5%);

	/* box-color */
	--box-color: #f4f4f4;

	/* Spacing */
	--spacing-1: 0.625rem;
	--spacing-2: 1.25rem;
	--spacing-3: 1.875rem;
	--spacing-4: 2.5rem;
	--spacing-5: 3.125rem;
	--spacing-6: 3.75rem;
	--spacing-7: 4.375rem;
	--spacing-8: 5rem;
	--spacing-9: 5.625rem;
	--spacing-10: 6.25rem;
}
