svg.MuiSvgIcon-root.icon-color-scheme {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 9px;

	&.green-color-scheme {
		color: #9cecb2;
		background-color: #e3ffea;
	}

	&.blue-color-scheme {
		color: #80a7ff;
		background-color: #e9f0ff;
	}

	&.pink-color-scheme {
		color: #f380ff;
		background-color: #fce0ff;
	}

	&.yellow-color-scheme {
		color: #e0e472;
		background-color: #fdffcc;
	}

	&.orange-color-scheme {
		color: #ffbb80;
		background-color: #ffebd5;
	}
}