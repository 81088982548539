.breadcrumbs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 0 0 var(--spacing-3);

    &__item {
        color: var(--base-link-color);
        background-color: #e9f0ff;
        margin: 0 30px 0 0;
        border-radius: 4px;
        position: relative;
        font-size: 0.875rem;
        font-weight: var(--font-weight-regular);

        &::after {
            content: "\279E";
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translate(100%, -50%);
            color: var(--color-1);
        }

        &-link {
            display: flex;
            padding: 8px;
            color: inherit;
            text-decoration: none;
        }

        &:first-of-type {
            &::after {
                display: none;
            }
        }
    }
}