.integrations-connect {
	display: grid;
	grid-gap: var(--spacing-2);
	grid-template-columns: repeat(3, 1fr);

	&__item {
		box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 5%);
		border-radius: 6px;
		display: flex;
		background-color: #fff;
		flex-direction: column;
		border: 1px solid #f6f6f7;

		&-header {
			display: flex;
			align-items: center;
			padding: var(--spacing-2) var(--spacing-2) 0;
		}

		&-icon {
			margin-right: var(--spacing-1);

			img {
				width: 100%;
				height: 100%;
				max-width: 40px;
			}
		}

		&-title {
			font-family: var(--font-family-heading);
			font-size: 1.25rem;
			font-weight: var(--font-weight-bold);
			color: var(--color-heading);
		}

		&-content {
			padding: var(--spacing-3) var(--spacing-2);
		}

		&-description {
			color: #8897a1;
			font-weight: var(--font-weight-regular);
		}

		&-setup {
			border-top: 1px solid #f6f6f7;
		}

		&-view {
			padding: var(--spacing-2);
			text-align: right;

			a {
				color: var(--color-2);
				font-weight: var(--font-weight-bold);
				text-decoration: none;
			}
		}

		.MuiSwitch-root {
			margin-left: auto;
			right: -10px;
		}
	}
}

.manage-api-key {
	display: flex;
	flex-direction: column;
	align-items: self-start;

	&__value {
		padding: var(--spacing-1);
		color: var(--base-link-color);
		background-color: #e9f0ff;
		border-radius: 4px;
		display: inline-flex;
		margin: 0 0 var(--spacing-2);
		cursor: pointer;
	}


	&__controls {
		display: flex;
		grid-gap: var(--spacing-2);
	}
}
