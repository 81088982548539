body {
	.btn {
		font-family: var(--base-font-family);
		font-size: autosize(1040, 1920, 16, 18);
		width: auto;
		background-color: var(--base-button-color);
		font-weight: var(--font-weight-medium);
		box-shadow: none;
		border: none;
		border-radius: 6px;
		padding: 12px 25px;
		text-transform: unset;
		max-height: 56px;
		letter-spacing: normal;

		&:hover,
		&:focus,
		&:active {
			background-color: var(--hover-button-color);
		}

		&.btn--small,
		&.MuiButton-sizeSmall {
			padding: 8px 18px;
			font-size: 1rem;
			border-radius: 6px;
			max-height: 44px;
		}

		&[disabled] {
			color: #fff;
			background-color: var(--disabled-button-color);

			&:hover,
			&:focus,
			&:active {
				background-color: var(--disabled-button-color);
			}
		}
	}

	.btn.primary-btn {
		svg {
			color: inherit;
		}
	}

	.btn.secondary-btn {
		color: var(--base-link-color);
		background-color: #e9f0ff;
		box-shadow: none;

		svg {
			color: var(--base-link-color);
		}

		&[disabled] {
			color: #9ebafb;
		}
	}

	.btn.third-btn {
		color: var(--base-link-color);
		background-color: #e9f0ff;
		box-shadow: none;

		svg {
			color: var(--base-link-color);
		}

		&[disabled] {
			color: #9ebafb;
		}
	}

	.btn.light-grey {
		background-color: #f9fafc;
		box-shadow: none;
		color: #6c6e75;

		svg {
			color: #6c6e75;
		}
	}

	.MuiButtonGroup-root .btn {
		width: 100%;
		background-color: #fff;
	}

	.MuiTabs-scroller {
		.MuiButtonBase-root {
			color: #8897a1;
			font-family: var(--base-font-family);
			font-size: 0.875rem;
			font-weight: var(--font-weight-regular);
		}
	}
}
