.editor-usage {
	&__label {
		color: var(--color-text);
		font-weight: var(--font-weight-medium);
	}

	&__bar {
		color: var(--base-link-color);
		background-color: #e9f0ff;
		padding: 8px 12px;
		border-radius: 6px;
		font-weight: var(--font-weight-medium);
		font-size: 1rem;
		position: relative;

		&-text {
			z-index: 1;
			position: relative;
			display: flex;
			align-items: center;

			svg {
				margin-right: var(--spacing-1);
			}
		}

		&-progress {
			height: 4px;
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			margin: 0 auto;
			background-color: var(--color-1);
			border-radius: 6px;
			max-width: calc(100% - 12px);
		}
	}
}

.settings-usage {
	display: inline-flex;
	flex-direction: column;
	margin: 0 0 var(--spacing-3);

	&__title {
		display: block;
		font-family: var(--font-family-heading);
		font-size: autosize(1041, 1920, 20, 22);
		font-weight: var(--font-weight-semibold);
		color: var(--color-heading);
		margin: 0 0 var(--spacing-2);
	}

	&__bar {
		color: var(--base-link-color);
		background-color: #e9f0ff;
		padding: 8px 12px;
		border-radius: 6px;
		font-weight: var(--font-weight-medium);
		font-size: 1rem;
		position: relative;
		max-width: max-content;

		&-text {
			z-index: 1;
			position: relative;
			display: flex;
			align-items: center;

			svg {
				margin-right: var(--spacing-1);
			}
		}

		&-progress {
			height: 4px;
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			margin: 0 auto;
			background-color: var(--color-1);
			border-radius: 6px;
			max-width: calc(100% - 12px);
		}
	}

	&__refill-date {
		margin: var(--spacing-1) 0 0;
		color: #8897a1;
		font-family: var(--base-font-family);
		font-size: 1rem;
		font-weight: var(--font-weight-regular);
	}

	.reactivate-subscription__btn,
	.cancel-subscription__btn {
		margin: var(--spacing-3) 0 0;
		max-width: max-content;
	}

	.btn.cancel-subscription__btn {
		color: #9ebafb;
	}
}