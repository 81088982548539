.login {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	display: inline-block;

	&__wrapper {
		width: 100%;
		max-width: 450px;
		margin: 0 auto;
	}

	&__logo {
		width: 50px;
		margin: var(--spacing-3) auto var(--spacing-2);

		img {
			width: 100%;
		}
	}

	&__container {
		background-color: #fff;
		border: 1px solid #ebedef;
		border-radius: 10px;
		box-shadow: 0 2px 25px rgb(0 0 0 / 5%);
		margin: var(--spacing-3) auto var(--spacing-2);
		max-width: 450px;
		padding: 60px 40px;
		padding: var(--spacing-6) var(--spacing-4);
		position: relative;
		width: 100%;

		&[disabled]:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.5);
			z-index: 1;
		}
	}

	&__title {
		font-family: var(--font-family-heading);
		font-size: autosize(1041, 1920, 30, 36);
		font-weight: var(--font-weight-black);
		color: var(--color-heading);
		margin: 0;
		text-align: center;
	}

	&__description {
		font-family: var(--base-font-family);
		font-weight: var(--font-weight-regular);
		color: var(--color-text);
		font-size: 1.125rem;
		margin: var(--spacing-1) 0 0;

		span {
			font-weight: var(--font-weight-semibold);
			color: var(--base-button-color);
		}
	}

	&__form {
		margin: var(--spacing-3) 0 0;
	}

	&__separator {
		width: 100%;
		background: inherit;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		margin: var(--spacing-1) 0;

		&-text {
			font-weight: var(--font-weight-regular);
			color: #8897a1;
			background: #fff;
			padding: 0 var(--spacing-1);
		}

		span:not([class]) {
			content: "";
			width: 50%;
			height: 100%;
			border-bottom: 2px solid #f2f3f4;
			position: absolute;
			top: 0;
			transform: translateY(calc(-50% + 1px));
			z-index: -1;
		}

		span:not([class]):first-of-type {
			left: 0;
		}

		span:not([class]):last-of-type {
			right: 0;
		}
	}

	button.google-btn {
		width: 100%;
		color: var(--base-link-color-2) !important;
		background-color: #e9f0ff !important;
		border: 2px solid #f2f3f4 !important;
		border-radius: 6px !important;
		padding: 7px 25px !important;
		font-size: autosize(1040, 1920, 16, 18) !important;
		font-weight: var(--font-weight-regular) !important;
		box-shadow: none !important;
		align-items: center !important;
		justify-content: center !important;
		cursor: pointer !important;
		border: none !important;

		&:hover,
		&:focus {
			opacity: 1 !important;
			border-color: #a1abb8 !important;
		}

		div {
			padding: 0 !important;
			background-color: #e9f0ff !important;
		}

		span {
			font-family: var(--base-font-family);
		}
	}

	&__login {
		color: #8897a1;
		font-weight: var(--font-weight-regular);
		z-index: 1;
		font-family: var(--base-font-family);
		display: block;
		text-align: center;
		font-size: 1rem;
		position: absolute;
		top: 0;
		right: 0;
		margin: var(--spacing-2) var(--spacing-3);

		a {
			color: inherit;
			text-decoration: none;

			&:hover,
			&:focus {
				color: inherit;
				text-decoration: underline;
			}
		}
	}

	&__container-login &__error-message,
	&__container-details &__error-message {
		margin: 0;
	}

	&__footer {
		display: flex;
		margin: 0 auto var(--spacing-4);

		nav {
			margin: 0 auto;
		}

		ul {
			list-style-type: none;
			display: flex;
			grid-gap: var(--spacing-2);
			padding: 0;

			li,
			a {
				color: #8897a1;
				text-decoration: none;
			}

			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}