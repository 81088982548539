.backoffice {
	table {
		width: 100%;
		text-align: left;
		border-spacing: 0;

		tr {
			td {
				padding: 10px;
			}
		}

		tr:nth-child(even) {
			background-color: #f9fafc;
		}
	}
}