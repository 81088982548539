.template-form {
	width: 100%;
	height: calc(100vh - 50px);
	padding: var(--spacing-2);
	position: relative;
	overflow: auto;
	position: static;

	&__info {
		margin: 0 0 var(--spacing-4);
	}

	&__info-title {
		margin-top: 0;
		display: flex;
		gap: var(--spacing-1);
		align-items: center;

		svg {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			padding: 9px;
			color: #9cecb2;
			background-color: #e3ffea;
		}
	}

	&__info-text {
		color: #8897a1;
	}

	.form__fields {
		grid-template-columns: 1fr 1fr;
	}

	[class*="template-form__field__"] {
		grid-column: auto / span 2;

		&.lang,
		&.tone {
			grid-column: auto / span 1;
		}
	}

	&__static-submit {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		padding: var(--spacing-3) var(--spacing-2);
		width: 100%;
		margin: 0;

		.btn {
			width: 100%;
		}
	}

	&__field__slider-wrapper {
		p {
			text-align: center;
			font-family: var(--base-font-family);
			line-height: var(--base-line-height);
			color: var(--color-text);
			font-weight: var(--font-weight-medium);
		}
	}
}