.members {
	&-overview {
		&__title {
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 20, 22);
			font-weight: var(--font-weight-semibold);
			color: var(--color-heading);
			margin: 0 0 var(--spacing-2);
		}

		&__current {
			&-text {
				font-size: 1rem;
				color: #8897a1;
				font-weight: var(--font-weight-medium);
				margin: 5px 0 0;

				span {
					text-decoration: none;
					color: var(--color-1);
				}
			}
		}

		&__invite {
			margin: var(--spacing-1) 0 0;
		}
	}
}