* {
	box-sizing: border-box;
}

html {
	font-size: var(--base-font-size);
}

body {
	position: relative;
	font-family: var(--base-font-family);
	line-height: var(--base-line-height);
	/* -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; */
}

.app {
	display: flex;
	width: 100vw;
	height: 100vh;
}

.page-loading {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 99;

	div {
		width: 100%;
		height: 100%;
	}

	svg {
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
	}
}

.no-result {
	&__message {
		color: #8897a1;
		font-weight: var(--font-weight-regular);
	}
}

.CookieDeclaration,
#CookiebotWidget {
	display: none;
}