.pricing-plans {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	width: 100%;
	grid-gap: var(--spacing-2);

	&__plan {
		width: 100%;

		&-title {
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 20, 22);
			font-weight: var(--font-weight-medium);
			color: var(--color-heading);
			margin: 0 0 var(--spacing-1);
		}

		&-description {
			color: #8897a1;
			font-weight: var(--font-weight-light);
		}

		&-price {
			margin: var(--spacing-2) 0 0;
			grid-gap: var(--spacing-1);

			&-amount {
				color: var(--color-heading);
				font-weight: var(--font-weight-bold);
				font-size: autosize(1041, 1920, 24, 30);
				margin-right: 5px;
			}

			&-currency {
				color: var(--color-heading);
				margin-right: 5px;
			}

			&-recurring {
				color: #8897a1;
				font-size: 14px;
			}
		}

		&-upgrade {
			margin: var(--spacing-1) 0;
		}

		&-features {
			margin: var(--spacing-2) 0 0;

			&-title {
				color: var(--color-heading);
				margin: 0 0 var(--spacing-1);
			}
		}

		&-feature {
			display: flex;
			align-items: center;
		}

		&-feature-info {
			font-weight: var(--font-weight-light);
			color: #8897a1;
			margin-top: -5px;
		}

		&-feature-icon {
			color: var(--color-1);
			margin-right: var(--spacing-1);

			svg {
				width: 20px;
			}
		}
	}
}