.right-panel {
	width: calc(100% - 300px);
	margin-left: 300px;
	padding: var(--spacing-4) var(--spacing-4) 0;

	.editor & {
		width: 65%;
		margin-left: 35%;
		padding: 0;
	}

	&.maximized {
		background-color: #fff;
		width: calc(100% - 480px);
		margin-left: 80px;
	}

	&__content-view {
		background-color: #f9fafc;
		padding: 70px 60px;
		margin: 0 auto;
		min-height: 100%;

		&-inner {
			background-color: #fff;
			border-radius: 8px;
			box-shadow: 0 24px 24px -18px rgb(69 104 129 / 33%), 0 9px 45px 0 rgb(114 119 160 / 12%);
			padding: 70px 50px;
			min-height: 450px;
		}
	}
}