.content-creation {
	margin: var(--spacing-7) 0 0;

	&__title {
		font-family: var(--font-family-heading);
		font-size: autosize(1041, 1920, 20, 22);
		font-weight: var(--font-weight-semibold);
		color: var(--color-heading);
		margin: 0 0 var(--spacing-2);
	}
}
