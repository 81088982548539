.top-panel {
	width: 100%;
	display: flex;
	flex-direction: row;

	&__page {
		margin-right: auto;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	&__page-title {
		font-family: var(--font-family-heading);
		font-size: autosize(1041, 1920, 24, 30);
		font-weight: var(--font-weight-extrabold);
		color: var(--color-heading);
		margin: 0 0 var(--spacing-1);
	}

	&__page-description {
		margin: 0;
		color: #8897a1;
		font-weight: var(--font-weight-medium);
	}

	&__page-title,
	&__page-description {
		&.is-editable {
			position: relative;
			cursor: text;
			padding: 0 var(--spacing-2) 0 0;

			&:hover {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}

			&::before {
				opacity: 0;
				visibility: hidden;
				content: "";
				background-color: #e9f0ff;
				position: absolute;
				left: 0;
				top: 0;
				max-width: calc(100% - var(--spacing-2));
				width: calc(100% + var(--spacing-2));
				height: calc(100% + var(--spacing-1));
				z-index: -1;
				margin: -5px 0 0 -10px;
				border-radius: 4px;
				transition: 150ms ease-in-out;
			}

			input {
				width: 100%;
				font-family: inherit;
				font-size: inherit;
				color: inherit;
				background: transparent;
				border: none;
				box-shadow: none;
				font-weight: inherit;
				padding: 5px 10px;
				margin: -5px -10px;
				height: 100%;
				outline: none;
				border-radius: 4px;
				appearance: none;

				&:focus {
					background: #e9f0ff;
				}
			}
		}
	}
}
