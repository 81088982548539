.settings-menu {
	margin: var(--spacing-3) 0;

	&__nav {
		ul {
			display: flex;
			list-style-type: none;
			padding: 0 0 var(--spacing-1);
			grid-gap: var(--spacing-2);
			border-bottom: 1px solid #eee;
		}

		ul a {
			text-decoration: none;
			border-bottom: 4px solid transparent;
			border-radius: 4px;

			&.active {
				li {
					color: #80a7ff;
				}
			}
		}

		ul li {
			position: relative;
			color: #8897a1;
			font-family: var(--base-font-family);
			font-size: 1rem;
			font-weight: var(--font-weight-regular);
			border-radius: 15px;
		}
	}
}