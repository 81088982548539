.content-blocks {
	margin: var(--spacing-5) 0 0;
}

.content-block {
	width: 100%;
	display: flex;
	position: relative;
	margin: 0 0 var(--spacing-3);
	border-radius: 10px;

	&__type {
		position: absolute;
		width: 100px;
		left: -120px;
		color: #8897a1;
		font-weight: var(--font-weight-light);
		text-transform: uppercase;
		font-size: 12px;
		top: calc(50% - 10px);
		text-align: right;
		word-break: break-word;
	}

	&__text {
		font-family: var(--font-family-heading);
		font-weight: 300;
		font-size: 1rem;
		color: #8897a1;
		line-height: 32px;
	}

	&__toggle {
		top: calc(50% - 18px);
		position: absolute;
		left: -60px;
	}
}