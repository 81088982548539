.draft-editor {
	width: 100%;
	margin: var(--spacing-2) 0 0;

	&__toolbar {
		border: none;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
		padding: var(--spacing-1) 0 calc(var(--spacing-1) - 4px);

		.rdw-option-wrapper {
			border: none;
			box-shadow: none;
			min-width: 30px;
			height: 30px;

			&.rdw-option-active {
				color: var(--color-1);
				background-color: #f8f9fc;
			}

			&:hover {
				color: var(--color-1);
				background-color: #f8f9fc;
			}
		}

		.rdw-dropdown-wrapper {
			border: none;
			box-shadow: none;
		}
	}

	&__inline {
		border: none;
	}

	.rdw-editor-main {
		position: relative;
		overflow: visible;
		color: var(--color-text);
		min-height: 100px;

		&:hover {
			&::before {
				opacity: 1;
				visibility: visible;
			}
		}

		&::before {
			opacity: 0;
			visibility: hidden;
			content: "";
			background-color: #e9f0ff;
			position: absolute;
			left: 0;
			top: 0;
			width: calc(100% + var(--spacing-2));
			height: calc(100% + var(--spacing-1));
			z-index: -1;
			margin: -5px 0 0 -10px;
			border-radius: 4px;
			transition: 150ms ease-in-out;
		}
	}

	&__inline-toolbar {
		width: auto;
		background-color: #fff;
		box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
		display: flex;
		align-items: center;
		position: absolute;
		z-index: 9;
		margin-top: -5px;
		padding: 8px 14px;
		color: #8897a1;
		font-family: var(--base-font-family);
		font-size: 15px;
		font-weight: var(--font-weight-regular);
		border-radius: 6px;

		&__actions,
		&__stats {
			display: flex;
			align-items: center;
			grid-gap: 14px;
		}

		&__spacer {
			height: 12px;
			width: 1px;
			background-color: #8897a1;
			margin: 0 var(--spacing-1);
		}

		&__action {
			display: flex;
			align-items: center;
			cursor: pointer;
			pointer-events: all;

			svg {
				font-size: 15px;
				color: inherit;
				margin-left: 4px;
			}

			&:not([disabled]) {
				color: var(--color-1);
			}
		}
	}

	h1,
	h2
	h3 {
		margin: 0;
	}

	.public-DraftStyleDefault-block {
		margin: var(--spacing-2) 0 var(--spacing-1);
	}
}