.form {
	&__fields {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 25px;

		.MuiOutlinedInput-root {
			font-family: var(--base-font-family);
			background-color: #fff;
			border-radius: 6px;
			font-size: 15px;
			color: var(--color-text);
			box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 5%);
			padding: 0;

			&:focus,
			&:hover,
			&:active,
			&:visited {
				fieldset.MuiOutlinedInput-notchedOutline {
					border-color: #a1abb8;
				}
			}

			fieldset.MuiOutlinedInput-notchedOutline {
				border-width: 1px;
				border: 1px solid #e1e7ef;
				top: -9px;
			}

			.MuiInputAdornment-root {
				margin-left: 14px;
				margin-right: 0;
				margin-top: 0;
			}

			.MuiAutocomplete-input,
			.MuiSelect-select {
				padding: 11px 14px 14px;
			}
		}

		.MuiAutocomplete-clearIndicator {
			display: none;
		}

		.MuiInputLabel-root {
			line-height: 1;
			top: -3px;

			&.Mui-Focused,
			&.MuiInputLabel-shrink {
				font-weight: var(--font-weight-bold);
				top: 0;
				background: #fff;

				&.has-tooltip {
					display: flex;
					align-items: center;
					top: -1px;

					svg {
						font-size: 18px;
						margin-left: 4px;
					}
				}
			}
		}

		.MuiOutlinedInput-input {
			padding: 11px 14px 14px;
		}

		.MuiInputBase-adornedStart {
			.MuiOutlinedInput-input {
				padding: 14px 14px 14px 5px;
			}
		}

		.MuiFilledInput-root {
			font-family: var(--base-font-family);
			background-color: #fff;
			border-radius: 8px;
			border: 1px solid #d9e0e5;
			box-shadow: 0 1px 2px 0 #f9fafc;
			font-size: 15px;

			&::after {
				display: none;
			}

			&:active,
			&:visited,
			&:hover,
			&:focus {
				background-color: #fff;
			}

			&::before {
				content: unset;
			}
		}

		.MuiFilledInput-input {
			height: 20px;
		}

		&-label,
		.MuiFormLabel-root,
		.MuiInputLabel-root,
		.MuiFormHelperText-root,
		.MuiFormControlLabel-label {
			font-family: var(--base-font-family);
			font-weight: var(--font-weight-regular);
			color: var(--color-text);
			font-size: 1rem;
		}

		.MuiFormHelperText-root {
			letter-spacing: 0;
			color: #8897a1;
			font-size: 13px;

			a {
				text-decoration: none;
				color: inherit;
			}
		}

		.MuiInputAdornment-root {
			margin-right: 5px;
			margin-top: 20px;

			p {
				font-size: 14px;
				font-family: var(--base-font-family);
				font-weight: var(--font-weight-regular);
				color: #8897a1;
			}
		}

		.btn {
			padding: 12px 25px;
		}

		svg {
			color: #8897a1;
		}

		.Mui-checked svg {
			color: var(--base-button-color);
		}

		&-single-input-button {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: var(--spacing-2);
		}

		&-one-column {
			grid-column: 1 / 3;
		}

		.MuiRating-iconFilled {
			svg {
				color: #ff6d75;
			}
		}

		.MuiRating-iconHover {
			svg {
				color: #ff6d75;
			}
		}

		legend {
			font-family: var(--font-family-heading);
			font-weight: var(--font-weight-regular);
			color: #8897a1;
			margin: 0 0 5px;
		}

		.MuiSlider-markLabel {
			font-family: var(--base-font-family);
			font-weight: var(--font-weight-bold);
			color: var(--color-text);
			font-size: 0.75rem;
		}
	}

	&__error-message {
		font-family: var(--base-font-family);
		color: var(--color-error);
		font-weight: var(--font-weight-regular);
		font-size: 15px;
		margin: 0;
	}

	&__loading {
		position: absolute;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
		z-index: 2;
	}
}

.MuiAutocomplete-listbox {
	padding: 0;
	box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 5%);

	.category-select__option-icon {
		display: flex;

		svg {
			margin-right: 8px;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			padding: 6px;
		}

		&.option--type-1 {
			svg {
				color: #9cecb2;
				background-color: #e3ffea;
			}
		}

		&.option--type-2 {
			svg {
				color: #80a7ff;
				background-color: #e9f0ff;
			}
		}

		&.option--type-3 {
			svg {
				color: #ed93ef;
				background-color: #ffe7fd;
			}
		}
	}
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
	font-family: var(--base-font-family);
	font-size: 15px;
	color: var(--color-text);
	padding: 8px 16px;
	border-bottom: 1px solid #eee;
	background-color: #fff;

	&:last-of-type {
		border-bottom: none;
	}

	&[aria-selected="true"],
	&:hover {
		color: var(--color-1);
		background-color: inherit;
	}

	img {
		margin-right: 8px;
	}
}