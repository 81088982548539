.content-panel {
	max-width: 400px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid #ebedef;
	box-shadow: 0 2px 4px rgb(0 0 0 / 5%);
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	flex-shrink: 0;
	position: fixed;
	top: 0;
	right: 0;
	padding: var(--spacing-4);

	&.type--1 {
		.content-panel__title svg {
			color: #9cecb2;
			background-color: #e3ffea;
		}
	}

	&.type--2 {
		.content-panel__title svg {
			color: #e0e051;
			background-color: #ffffc9;
		}
	}

	&.type--3 {
		.content-panel__title svg {
			color: #ed93ef;
			background-color: #ffe7fd;
		}
	}

	&__title {
		display: flex;
		align-items: center;

		svg {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			padding: 9px;
			margin-right: 12px;
		}

		span {
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 24, 30);
			font-weight: var(--font-weight-extrabold);
			color: var(--color-heading);
		}
	}

	&__description {
		color: #8897a1;
		font-weight: var(--font-weight-regular);
		margin: var(--spacing-1) 0 0;
	}

	&__blocks {
		margin: var(--spacing-2) 0 0;
	}

	&__block {
		background-color: #f9fafc;
		padding: 15px 0;
		border-bottom: 2px solid #eee;
		display: grid;
		grid-template-columns: 40px 1fr;
		grid-template-rows: repeat(2, 1fr);
		grid-template-areas: "svg title" "svg desc";
		user-select: none;

		&:last-of-type {
			border: none;
		}

		&-icon {
			grid-area: svg;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:hover {
				svg {
					color: var(--base-button-color);
				}
			}

			svg {
				color: #8897a1;
				pointer-events: none;
			}
		}

		&-title {
			grid-area: title;
			font-family: var(--font-family-heading);
			font-size: autosize(1041, 1920, 16, 20);
			font-weight: var(--font-weight-semibold);
			color: var(--color-heading);
		}

		&-description {
			grid-area: desc;
			color: #8897a1;
			font-weight: var(--font-weight-regular);
		}
	}

	form {
		margin: var(--spacing-4) 0 0;
	}

	.form__fields .MuiFilledInput-root {
		background-color: #fff !important;
	}

	.form__fields .MuiFormHelperText-root {
		color: #8897a1;
	}

	&__create-content-btn {
		svg {
			color: #fff;
		}
	}

	&__copy-content-btn {
		margin-top: auto !important;
	}
}
