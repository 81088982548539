.draggable-item {
	width: 100%;
	margin: 0 0 var(--spacing-4);
	padding: 0;
	border-radius: 6px;
	position: relative;

	&:hover {
		.draggable-item__indicator {
			display: absolute;
			right: 0;
			top: 0;
		}
	}

	&__statistics {
		display: flex;
		grid-gap: var(--spacing-2);
	}

	&__statistic {
		display: flex;
		grid-gap: 5px;
		color: var(--color-1);
		font-size: var(--font-size-small);
		align-items: center;

		&-icon {
			display: flex;

			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	&__title {
		font-weight: var(--font-weight-semibold);
		font-size: 1rem;
		color: var(--color-heading);
		margin: 0;
		grid-column: 1 / 2 span;
	}

	&__indicator {
		display: none;
		align-items: center;
		justify-content: right;
		margin-left: var(--spacing-2);

		svg {
			cursor: grab;
			color: #8897a1;
		}
	}

	&__content {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: var(--spacing-1);
	}

	&__output {
		display: flex;
		flex-direction: column;
		font-weight: var(--font-weight-light);
		color: #8897a1;
		max-width: 800px;
		line-height: 1.7;
	}

	&__options {
		display: flex;
		grid-gap: var(--spacing-1);

		.btn {
			margin: 0 !important;
		}
	}
}
