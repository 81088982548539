.MuiPaper-root.MuiAlert-standard {
	padding: 4px 10px;
	font-size: 1rem;
	border-radius: 6px;
	font-family: var(--base-font-family);
	font-weight: var(--font-weight-medium);
	font-size: 1rem;

	&.MuiAlert-standardSuccess {
		color: #6fdb8e;
		background-color: #e3ffea;

		svg {
			color: #6fdb8e;
		}
	}
}