.document-list {
	&__item {
		&.type--1 {
			.document-list__item-icon svg {
				color: #9cecb2;
				background-color: #e3ffea;
			}
		}

		&.type--project {
			.document-list__item-icon svg {
				color: #80a7ff;
				background-color: #e9f0ff;
			}
		}

		&.type--2 {
			.document-list__item-icon svg {
				color: #e0e051;
				background-color: #ffffc9;
			}
		}

		&.type--3 {
			.document-list__item-icon svg {
				color: #ed93ef;
				background-color: #ffe7fd;
			}
		}
	}
}
